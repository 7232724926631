import React from 'react';
import styles from './index.module.css';

import EmailIcon from '../../assets/email-icon.svg';
import PhoneIcon from '../../assets/phone-icon.svg';
import AddressIcon from '../../assets/address-icon.svg';

const ContactInfo = props => {
  const { footer, email, phone, address } = props;
  return (
    <div className={styles.container}>
      <div className={footer ? styles.footerItem : styles.item}>
        <a
          className={footer ? styles.footerInfo : styles.contactInfo}
          href="mailto: sales@rrsllc.org"
        >
          <EmailIcon
            className={footer ? styles.phoneFooterIcon : styles.contactIcon}
          />
          {email}
        </a>
      </div>
      <div className={footer ? styles.footerItem : styles.item}>
        <a
          className={footer ? styles.footerInfo : styles.contactInfo}
          href="tel: 408-981-7836"
        >
          <PhoneIcon
            className={footer ? styles.phoneFooterIcon : styles.contactIcon}
          />
          {phone}
        </a>
      </div>
      <div className={footer ? styles.footerItem : styles.item}>
        <a
          className={footer ? styles.footerInfo : styles.contactInfo}
          href="https://www.google.com/maps/place/RRS+Auto+Group+-+Dealership+and+Sports+Car+Storage/@37.3840472,-121.9166097,17z/data=!3m1!4b1!4m5!3m4!1s0x808fcbe4bc3b830f:0x6b383222a2debd4!8m2!3d37.384043!4d-121.914421"
        >
          <AddressIcon
            className={footer ? styles.phoneFooterIcon : styles.contactIcon}
          />
          {address}
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
