import React from 'react';

import Fade from 'react-reveal/Fade';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';
import ContactInfo from '../components/contact-info';
import styles from './contact.module.css';

import useQuery from '../queries/contact-us-query';

const Contact = () => {
  const { contactUsCard, contactInfo } = useQuery();

  return (
    <Layout>
      <SEO title="Contact" />
      <iframe
        title="RRS Store Location"
        height="500"
        width="100%"
        frameBorder="0"
        style={{ border: 0, maxWidth: '100%' }}
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJD4M7vOTLj4AR1OstKiKDswY&key=AIzaSyA0IX1mT6sO28QHzzYiM5y5OODK5PANxgo&zoom=13"
        allowFullScreen
      />
      <Fade cascade duration={3000}>
        <Card
          className={styles.contactCard}
          title={contactUsCard.title}
          subtitle={contactUsCard.subtitle}
          desc={
            <ContactInfo
              email={contactInfo.email}
              phone={contactInfo.phoneNumber}
              address={contactInfo.address}
            />
          }
        />
      </Fade>
    </Layout>
  );
};

export default Contact;
