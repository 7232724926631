import { graphql, useStaticQuery } from "gatsby"

const useQuery = () => {
    const query = useStaticQuery(
        graphql`
    query {
        contactUsCard: contentfulCard(
        contentful_id: { eq: "2fFL0j2RvlsVywQfT0Mmim" }
        ) {
        title
        subtitle
        }
        contactInfo: contentfulBusinessInformation(
        contentful_id: { eq: "7sUPvaBmu9HSe9ASYCz4CC" }
        ) {
        email
        phoneNumber
        address
        }
    }
`  )
    return query
}

export default useQuery;